html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;

  word-wrap: break-word;
}
* {
  --sb-thumb-color: rgba(51, 51, 51, 0.2);
  --sb-size: 14px;
}
body::-webkit-scrollbar {
  display: none;
}
*::-webkit-scrollbar {
  height: var(--sb-size);
  width: var(--sb-size);
}

*::-webkit-scrollbar-track {
  background: none;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  background-clip: padding-box;
  border-bottom: 2px solid transparent;
  border-left: 4px solid transparent;
  border-radius: 10px;
  border-right: 4px solid transparent;
  border-top: 2px solid transparent;
}

@supports not selector(::-webkit-scrollbar) {
  * {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}

input {
  font-size: 16px;
}

textarea {
  font-size: 16px;
}
